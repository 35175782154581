/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import PropTypes from 'prop-types';

const InlineError = ({ children, className }) => {
  return (
    <div
      className={className}
      css={(theme) => css`
				color: ${theme.colors.alert}
			`}
    >
      {children}
    </div>
  );
};

export default InlineError;
