/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useRef, useState } from 'react';
import ls from 'local-storage';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { signup } from '../actions/menu-actions';
import InlineError from './shared/inline-error';
import CenterForm from './shared/center-form';

const LoginForm = () => {
  const emailField = useRef();
  const passwordField = useRef();
  const [errors, setErrors] = useState([]);

  const onSignupSubmit = (e) => {
    e.preventDefault();
    if (!validateSubmit()) { return false; }

    const email = emailField.current.value;
    const password = passwordField.current.value;

    signup(email, password)
      .then(async (res) => {
        if (res.status === 200) {
          const resJSON = await res.json();
          ls('currentUser', resJSON.user);
          document.location = '/';
        } else if (res.status === 409) {
          toast('An account already exists with that email');
        }
      });
  };

  const validateSubmit = () => {
    const email = emailField.current.value;
    const password = passwordField.current.value;

    setErrors([]);

    const newErrors = [];

    if (!email || !email.match(/.+@.+\..+/)) {
      newErrors.push('You must enter a valid email');
    }

    if (!password) {
      newErrors.push('You must choose a password');
    }

    const PASSWORD_MIN_LENGTH = 8;
    if (password.length < PASSWORD_MIN_LENGTH) {
      newErrors.push(`Password must be at least ${PASSWORD_MIN_LENGTH} characters`);
    }

    setErrors(newErrors);

    if (newErrors.length > 0) {
      return false;
    }

    return true;
  };

  return (
    <>
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      <CenterForm>
        <form onSubmit={onSignupSubmit}>
          <h1>Sign up</h1>

          { errors && (
            <InlineError className='margin-bottom'>
              {errors.join(', ')}
            </InlineError>
          )}

          <label>
            <span>Email address</span>
            <input
              ref={emailField}
              id='email'
              name='email'
              type='email'
              autoComplete='username'
              required
              autoFocus
            />
          </label>

          <label>
            <span>Password</span>
            <input
              ref={passwordField}
              id='password'
              name='password'
              type='password'
              autoComplete='password'
              required
            />
          </label>

          <button
            id='signupbtn'
            className='btn-primary'
          >
            Sign up
          </button>

          <div
            css={css`
              margin-top:20px;
            `}
          >
            <a href='/login'>Log in</a>
          </div>
        </form>
      </CenterForm>
    </>
  );
};

export default LoginForm;
