/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'underscore';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import EditorDispatchContext from '../contexts/editor-dispatch-context';
import { reorderChunk } from '../actions/editor-actions';

import Chunk from './chunk.jsx';
import Loader from './shared/loader';

const Chunks = ({
  chunks,
  documentId,
}) => {
  const dispatch = useContext(EditorDispatchContext);

  if (!chunks) {
    return (
      <div
        css={css`
          flex:0 1 500px;
        `}
      />
    );
  }

  const renderChunk = (chunk) => {
    return (
      <Chunk
        key={chunk.id}
        chunk={chunk}
        documentId={documentId}
      />
    );
  };

  const onDragEnd = (result) => {
    const { draggableId } = result;
    const chunkId = parseInt(draggableId.split('-')[1]);
    const newPosition = result.destination.index;

    dispatch({
      type: 'chunkReordered',
      chunkId,
      newPosition,
    });

    reorderChunk(chunkId, newPosition);
  };

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId='chunks'>
        {(provided) => (
          <div
            css={css`
              flex:0 1 500px;
              font-size:$base-font-size;
              line-height: 1.5;
              overflow-y:auto;
               -ms-overflow-style: none;
              scrollbar-width: none;
              padding:50px 25px;
              display:flex;
              flex-direction:column;

              &::-webkit-scrollbar {
                display: none;
              }

              @media (max-width: 900px) {
                display:none;
              }
            `}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {sortBy(chunks, 'position').map(renderChunk)}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Chunks;
