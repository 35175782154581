/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useState, useContext } from 'react';
import CenterColumn from './shared/center-column';
import SubscriptionToggleOption from './subscription-manager/subscription-toggle-option';
import userContext from '../contexts/user-context';
import BackArrow from './shared/back-arrow';
import ListingTable from './shared/listing-table';

const SubscriptionManager = ({ suggestUpgrade }) => {
  const user = useContext(userContext);
  const [plan, setPlan] = useState(suggestUpgrade ? 'premium' : user.plan);

  const renderContinueButton = () => {
    const planWouldChange = () => {
      return plan !== user.plan;
    };

    if (!planWouldChange()) {
      return (
        <button
          className='btn-primary'
          type='button'
          disabled
        >
          Continue
        </button>
      );
    }

    const endpoint = (plan === 'premium' ? 'subscribe' : 'downgrade');

    return (
      <form
        action={`/api/subscriptions/${endpoint}`}
        method='POST'
        className='margin-top'
      >
        <input type='hidden' name='lookup_key' value='subscription' />
        <button
          id='checkout-and-portal-button'
          type='submit'
          className='btn-primary'
        >
          Continue
        </button>
      </form>
    );
  };

  return (
    <>
      <BackArrow
        url='/documents'
        label='Documents'
      />

      <CenterColumn>
        <h1>Account</h1>
        <ListingTable
          highlightOnHover={false}
          className='margin-bottom-more bigger'
        >
          <tr>
            <td>Email</td>
            <td>{user.email}</td>
          </tr>
        </ListingTable>

        <h2>Plan</h2>
        <div className='flex margin-bottom'>
          <SubscriptionToggleOption
            isToggled={plan === 'free'}
            isCurrent={user.plan === 'free'}
            onClick={() => setPlan('free')}
            name='Free'
            desc='Limited to 2 files'
            price='Free'
          />

          <SubscriptionToggleOption
            isToggled={plan === 'premium'}
            isCurrent={user.plan === 'premium'}
            onClick={() => setPlan('premium')}
            name='Premium'
            desc='Unlimited files'
            price='Suggested donation: $7/mo'
          />
        </div>

        <div
          css={css`
            text-align:right;
          `}
        >
          {renderContinueButton()}
        </div>

        <hr className='margin-top-more margin-bottom' />

        <p className='margin-bottom-more'>
          Lunette is funded by donations. If you'd like to pay an amount other than the suggested donation of $7/month, please <a href='mailto:support@lunette.app?subject=Premium'>email us</a> and let us know the amount you'd like to pay, if any.
        </p>

        <h2>Billing</h2>
        <form method='POST' action='/api/users/subscription_portal'>
          <button
            type='submit'
            className='btn-primary'
          >
            Manage billing
          </button>
        </form>
      </CenterColumn>
    </>
  );
};

export default SubscriptionManager;
