/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BackArrow = ({ url, label }) => {
  return (
    <Link
      to={url}
      css={(theme) => css`
        position:absolute;
        top:1rem;
        left:1rem;
        z-index: 2;
        text-decoration:none;
        
        img {
          vertical-align:middle;
          width:30px;
          height:30px;  
        }

        span {
          color: ${theme.colors.text3};
        }

        img {
          filter:${theme.filters.icon};
        }
      `}
    >
      <img src='/images/arrow-left.svg' />
      <span>{label}</span>
    </Link>
  );
};

BackArrow.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string.isRequired,
};

BackArrow.defaultProps = {
  label: 'Back',
};

export default BackArrow;
