/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import HTMLtoDOCX from 'html-to-docx';
import { saveAs } from 'file-saver';

const DownloadButton = ({ editor }) => {
  const onClick = () => {
    downloadDocx();
  };

  async function downloadDocx() {
    const htmlString = editor.getHTML();
	  const fileBuffer = await HTMLtoDOCX(htmlString);
	  saveAs(fileBuffer, 'document.docx');
  }

  return (
    <button
      type='button'
      onClick={onClick}
      className='nopadding margin-right'
      css={(theme) => css`
        img {
          filter:${theme.filters.icon}
        }
      `}
    >
      <img src='/images/icon-download.svg' />
    </button>
  );
};

export default DownloadButton;
