/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, {
  useEffect, useCallback, useContext, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  NodeViewWrapper, NodeViewContent, useEditor, EditorContent,
} from '@tiptap/react';
import { Draggable } from 'react-beautiful-dnd';
import Placeholder from '@tiptap/extension-placeholder';
import { debounce, isEmpty } from 'underscore';
import CommonExtensions from '../lib/tip-tap/common-extensions';
import {
  createChunk, updateChunk, duplicateChunk, deleteChunk,
} from '../actions/editor-actions';
import '../styles/placeholder.scss';
import OptionsMenu from './chunk/options-menu';
import GrabHandle from './chunk/grab-handle';
import EditorDispatchContext from '../contexts/editor-dispatch-context';
import MenuItem from './chunk/options-menu/menu/item';

const Chunk = ({
  chunk,
  documentId,
}) => {
  const [typing, setTyping] = useState(false);

  const dispatch = useContext(EditorDispatchContext);
  const debouncedSaveContent = useCallback(debounce(updateChunk, 1000), []);

  const onUpdate = ({ editor }) => {
    setTyping(true);
    debouncedSetTyping(false);
    debouncedSaveContent(chunk.id, editor);
  };

  const debouncedSetTyping = useCallback(debounce((newVal) => {
    setTyping(newVal);
  }, 1000), []);

  const editor = useEditor({
    extensions: [
      ...CommonExtensions,
      Placeholder.configure({
        placeholder: 'Add note...',
      }),
    ],
    content: JSON.parse(chunk.content),
    onUpdate,
  });

  const onAddNoteClick = () => {
    createChunk(documentId, { position: chunk.position + 1 })
      .then((res) => res.json())
      .then((newChunk) => {
        dispatch({
          type: 'chunkCreated',
          newChunk,
        });
      });
  };

  const onDeleteClick = () => {
    const shouldDelete = confirm('Delete this note?');
    if (!shouldDelete) { return; }

    dispatch({
      type: 'chunkDeleted',
      chunkId: chunk.id,
    });

    deleteChunk(chunk.id)
      .then((res) => res.json())
      .then((chunks) => {
        dispatch({
          type: 'chunksLoaded',
          chunks,
        });
      });
  };

  const onDuplicateClick = () => {
    duplicateChunk(chunk.id)
      .then((res) => res.json())
      .then((newChunk) => {
        dispatch({
          type: 'chunkCreated',
          newChunk,
        });
      });
  };

  return (
    <Draggable
      key={chunk.id}
      draggableId={`chunk-${chunk.id}`}
      index={chunk.position}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          css={css`

            &:last-of-type {

              .addbarwrap {

                .addbar {
                  &:after {
                    content:'';
                    position:fixed;
                    width:100%;
                    height:100%;
                  }
                }
              }
              
            }
          `}
        >
          <div
            className={typing ? 'typing' : ''}
            css={(theme) => css`
              border: 1px solid ${theme.colors.border};
              box-shadow: 0px 0px 6px rgba(41, 41, 41, 0.1);
              background-color:${theme.colors.background};
              border-radius: 5px;
              padding:1rem 23px 1rem 23px;
              font-family: ${theme.fonts.serif};
              min-height:1em;
              position:relative;

              .ProseMirror > * {
                &:first-of-type {
                  margin-top:0;
                }

                &:last-child {
                  margin-bottom:0;
                }
              }

              & > div > button:not(.active) {
                opacity:0;
                transition:opacity 0.25s;
              }

              &:hover:not(.typing) {
                button {
                  opacity:1;
                }

                .chunk-drag {
                  visibility:visible;
                }
              }
            `}
          >
            <GrabHandle
              {...provided.dragHandleProps}
              className='chunk-drag'
              css={css`
                visibility:hidden;
                z-index:2;
                position:absolute;
                left:5px;
                top:50%;
                margin-top:-8px;
              `}
            />
            <div
              css={css`
                position:absolute;
                right:0.5em;
                top:0.5em;
              `}
            >
              <OptionsMenu>
                <MenuItem
                  onClick={onDeleteClick}
                >
                  Delete
                </MenuItem>
                <MenuItem
                  onClick={onDuplicateClick}
                >
                  Duplicate
                </MenuItem>
              </OptionsMenu>
            </div>

            <EditorContent
              editor={editor}
            />
          </div>

          <div
            onClick={onAddNoteClick}
            className='addbarwrap'
            css={(theme) => css`
              opacity:0;
              box-sizing:border-box;
              padding:1rem 0;
              height:1px;
              transition:opacity 0.25s;
              position:relative;

              &:after {
                content:'';
                width:15px;
                height:15px;
                background:url('/images/plus.svg') center center no-repeat;
                position: absolute;
                right: -3px;
                border:3px solid ${theme.colors.background};
                top:4px;
                z-index: 51;
              }

              &:hover {
                cursor:pointer;
                opacity:1;
              }
            `}
          >
            <div
              className='addbar'
              css={(theme) => css`
                width:100%;
                height:0;
                border-top:1px solid ${theme.colors.border};
                border-radius:0;
                margin:0;
                padding:0;
              `}
            />
          </div>
        </div>
      )}
    </Draggable>
  );
};

Chunk.propTypes = {
};

export default Chunk;
