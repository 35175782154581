/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, {
  useContext, useEffect, useCallback, useReducer, useRef,
} from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Placeholder from '@tiptap/extension-placeholder';
import Gapcursor from '@tiptap/extension-gapcursor';
import { debounce, isEmpty, reject } from 'underscore';
import { Navigate, useParams } from 'react-router-dom';
import CommonExtensions from '../lib/tip-tap/common-extensions';

import EditorDispatchContext from '../contexts/editor-dispatch-context';
import editorReducer from '../reducers/editor-reducer';
import Chunks from './chunks';
import Toolbar from './toolbar';
import userContext from '../contexts/user-context';
import DocumentUpdateManager from '../lib/document-update-manager';
import {
  loadContent,
  updateTitle,
} from '../actions/editor-actions';
import '../styles/editor.scss';
import BackArrow from './shared/back-arrow';

const Editor = () => {
  const [state, dispatch] = useReducer(editorReducer, {
    chunks: null,
    saving: false,
  });

  const user = useContext(userContext);
  const { documentId } = useParams();

  // TODO this should be a global user check
  if (!user || !Number.isInteger(parseInt(documentId))) {
    return <Navigate to='/' replace />;
  }

  const initiateSave = useCallback(debounce(() => {
    updateManager.current.persist();
  }, 1000), []);

  const updateManager = useRef(new DocumentUpdateManager(documentId, dispatch));

  const onUpdate = ({ editor, transaction }) => {
    updateManager.current.registerSteps(transaction.steps);
    initiateSave();
  };

  const editor = useEditor({
    extensions: [
      ...CommonExtensions,
      Gapcursor,
      Placeholder.configure({
        placeholder: 'Write something…',
      }),
    ],
    onUpdate,
  });

  const onContentLoaded = (res) => {
    dispatch({
      type: 'chunksLoaded',
      chunks: res.doc.Chunks,
    });

    editor.commands.setContent(res.doc.content);
  };

  useEffect(() => {
    if (!editor) { return; }

    loadContent(editor, documentId)
      .then((res) => res.json())
      .then((res) => {
        onContentLoaded(res);
      });
  }, [editor]);

  return (
    <EditorDispatchContext.Provider value={dispatch}>
      <BackArrow
        url='/documents'
        label='Documents'
      />

      <div
        css={css`
        display:flex;
        max-width:1536px;
        margin:0 auto;
        height:100vh;
        overflow-x:scroll;
        `}
      >
        <div
          id='editor'
          css={(theme) => css`
            flex:3 3 62%;
            font-size:16px;
            line-height:22px;
            padding:50px 25px;
            height:100%;
            box-sizing:border-box;
            position:relative;

            @media(max-width:${theme.config.mobileBreakpoint}) {
              padding-top:0;
            }
          `}
        >
          <Toolbar
            editor={editor}
            saving={state.saving}
          />

          <div
            css={(theme) => css`
              font-family: ${theme.fonts.serif};
              max-width:650px;
              margin:0 auto;
              height:100%;
            `}
          >
            <div
              css={css`
                line-height: 2;
                overflow-y:scroll;
                overflow-x:hidden;
                height:100%;
                padding-top:50px;
                padding-bottom:50px;
                box-sizing:border-box;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                  display: none;
                }
              `}
            >
              <EditorContent
                editor={editor}
              />
            </div>
          </div>
        </div>

        <Chunks
          chunks={state.chunks}
          documentId={documentId}
        />
      </div>
    </EditorDispatchContext.Provider>
  );
};

export default Editor;
