import { on401 } from './authentication';
import { toast } from 'react-toastify';

const fetchWrap = {
  get: (url) => fetch(url, {
    method: 'GET',
    withCredentials: true,
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
  })
    .then(handleRes),
  post: (url, opts = {}) => fetch(url, {
    method: 'POST',
    withCredentials: true,
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: opts.body,
  })
    .then(handleRes),
  delete: (url) => fetch(url, {
    method: 'DELETE',
    withCredentials: true,
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
  })
    .then(handleRes),
};

const handleRes = (res) => {
  switch (res.status) {
  case 401:
    on401();
    break;
  case 403:
  case 500:
    toast('Something went wrong');
  }
  return res;
};

export default fetchWrap;
