/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { Component } from 'react';
import { Notifier } from '@airbrake/browser';
import { Link } from 'react-router-dom';
import CenterColumn from './center-column';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.airbrake = new Notifier({
      projectId: process.env.REACT_APP_AIRBRAKE_PROECT_ID,
      projectKey: process.env.REACT_APP_AIRBRAKE_API_KEY,
    });
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.airbrake.notify({
      error,
      params: { info },
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <CenterColumn>
          <div
            css={css`
              text-align:center;
            `}
          >
            <h1>Something went wrong</h1>
            <p>Sorry for the trouble. We're looking into it!</p>
          </div>
        </CenterColumn>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
