/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

const GrabHandle = (props) => {
  return (
    <div
      {...props}
      css={(theme) => css`
        background:url('/images/grab.svg') center center no-repeat;
        background-size:contain;
        width:10px;
        height:16px;
        padding:0;
        filter:${theme.filters.icon};
      `}
    />
  );
};

export default GrabHandle;
