/** @jsxImportSource @emotion/react */

import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const INTERVAL = 2000;

const SaveStatus = ({ saving }) => {
  const [isRecentlySaved, setIsRecentlySaved] = useState(false);
  const [showSaving, setShowSaving] = useState(false);

  const savingSince = useRef(null);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else if (saving) {
      setShowSaving(true);
      savingSince.current = Date.now();
    } else {
      const remainingDisplayTime = INTERVAL - (Date.now() - savingSince.current);

      setTimeout(() => {
        savingSince.current = null;
        setShowSaving(false);
        setIsRecentlySaved(true);
        setTimeout(() => {
          setIsRecentlySaved(false);
        }, INTERVAL);
      }, remainingDisplayTime < 0 ? 0 : remainingDisplayTime);
    }
  }, [saving]);

  const savingContent = (
    <div
      css={(theme) => css`
				border-radius:5px;
				display:inline-block;

				::before {
					display:inline-block;
					vertical-align:middle;
					margin-right:.62em;
					content:'';
					width:1em;
					height:1em;
          filter:${theme.filters.icon};
					background:url('/images/swap.svg') center center no-repeat;
				}
			`}
    >
      Saving
    </div>
  );

  const savedContent = (
    <div
      css={(theme) => css`
				border-radius:5px;
				display:inline-block;

				::before {
					display:inline-block;
					vertical-align:text-top;
					margin-right:.62em;
					content:'';
					width:1.1em;
					height:1.1em;
					background:url('/images/cloud.svg') center center no-repeat;
          filter:${theme.filters.icon};
					background-position-y:2px;
				}
			`}
    >
      {isRecentlySaved && 'Saved'}
    </div>
  );

  return showSaving ? savingContent : savedContent;
};

export default SaveStatus;
