import { useEffect, useCallback, useRef } from 'react';

const useCloseIfOutside = (onClose, opts = {}) => {
  const ref = useRef();
  const usedRef = opts.ref || ref;

  // NOTE: this approach is necessary to allow for
  // callbacks defined as arrow functions and other situations
  // that would otherwise cause the `useEffect` to run on every render.
  const callbackRef = useRef(onClose);
  callbackRef.current = onClose;

  const closeIfOutside = useCallback((e) => {
    // Check custom containers
    if (opts.containers) {
      const inCustom = opts.containers.some((cont) => {
        return cont.current && cont.current.contains(e.target);
      });

      if (inCustom) {
        return;
      }
    }

    // Check anchor container
    if (!usedRef.current) { return; }

    if (usedRef.current.contains(e.target) || !document.body.contains(e.target)) {
      return;
    }
    if (callbackRef.current) {
      callbackRef.current(e);
    }
  }, [usedRef, opts]);

  useEffect(() => {
    document.addEventListener('click', closeIfOutside, false);

    return () => {
      document.removeEventListener('click', closeIfOutside, false);
    };
  }, [closeIfOutside]);

  return usedRef;
};

export default useCloseIfOutside;
