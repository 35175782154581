/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useEditor, EditorContent } from '@tiptap/react';
import BackArrow from '../shared/back-arrow';
import { loadSnapshot } from '../../actions/editor-actions';
import CommonExtensions from '../../lib/tip-tap/common-extensions';

const Backup = () => {
  const { documentId, snapshotId } = useParams();
  const [snapshot, setSnapshot] = useState();

  const editor = useEditor({
    editable: false,
    extensions: [
      ...CommonExtensions,
    ],
    content: '',
  });

  useEffect(() => {
    if (!editor) { return; }

    loadSnapshot(snapshotId)
      .then((res) => res.json())
      .then((s) => {
        setSnapshot(s);
        editor.commands.setContent(s.content);
      });
  }, [editor]);

  return (
    <>
      <BackArrow
        url={`/documents/${documentId}/backups`}
        label='Verions'
      />

      <div
        css={css`
        max-width:1536px;
        margin:0 auto;
        height:100vh;
        overflow-x:scroll;
        `}
      >
        <div
          id='editor'
          css={css`
            font-size:16px;
            line-height:22px;
            padding:50px 25px;
            height:100%;
            box-sizing:border-box;
            position:relative;
          `}
        >
          <div
            css={(theme) => css`
              font-family: ${theme.fonts.serif};
              max-width:650px;
              margin:0 auto;
              height:100%;
            `}
          >
            <div
              css={css`
                line-height: 2;
                overflow-y:scroll;
                overflow-x:hidden;
                height:100%;
                padding-top:50px;
                padding-bottom:50px;
                box-sizing:border-box;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                  display: none;
                }
              `}
            >
              <EditorContent
                editor={editor}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Backup;
