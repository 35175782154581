import React, { useContext } from 'react';
import MenuDispatchContext from '../../../../contexts/menu-dispatch-context';

const MenuItem = ({
  onClick,
  children,
}) => {
  const dispatch = useContext(MenuDispatchContext);

  return (
    <li>
      <button
        onClick={(e) => {
          e.stopPropagation();
          dispatch({
            type: 'closeMenu',
          });
          onClick(e);
        }}
      >
        {children}
      </button>
    </li>
  );
};

export default MenuItem;
