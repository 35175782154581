/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import CenterForm from './shared/center-form';
import BackArrow from './shared/back-arrow';
import { setNewPassword, verifyPasswordToken } from '../actions/app-actions';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [tokenWasVerified, setTokenWasVerified] = useState(false);
  const [newPass, setNewPass] = useState();
  const [confirmNewPass, setConfirmNewPass] = useState();

  useEffect(() => {
    verifyPasswordToken(token)
      .then((res) => {
        switch (res.status) {
          case 200:
            setTokenWasVerified(true);
            break;
          case 406:
            toast('Invalid password reset token');
            navigate('/forgot_password');
        }
      });
  }, [tokenWasVerified]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (newPass !== confirmNewPass) {
      toast('Passwords do not match');
      return;
    }

    setNewPassword(token, newPass)
      .then((res) => {
        if (res.status === 200) {
          toast('Password updated. You may now log in.');
          navigate('/login');
        }
      });
  };

  if (!tokenWasVerified) { return; }

  return (
    <>
      <Helmet>
        <title>Reset password</title>
      </Helmet>

      <BackArrow
        url='/login'
        label='Login'
      />

      <CenterForm>
        <h1>Reset password</h1>
        <form
          onSubmit={onSubmit}
        >
          <label>
            <span>New password</span>
            <input
              type='password'
              value={newPass}
              onChange={(e) => setNewPass(e.currentTarget.value)}
              required
              autoFocus
            />
          </label>

          <label>
            <span>Confirm new password</span>
            <input
              type='password'
              value={confirmNewPass}
              onChange={(e) => setConfirmNewPass(e.currentTarget.value)}
              required
            />
          </label>

          <button
            className='btn-primary'
            type='submit'
            css={css`
              margin-top:1rem;
            `}
          >
            Submit new password
          </button>
        </form>
      </CenterForm>
    </>
  );
};

export default ResetPassword;
