import {
  saveContent,
} from '../actions/editor-actions';

import { any } from 'underscore';

class DocumentUpdateManager {
	constructor(documentId, dispatch) {
		this.documentId = documentId;
		this.dispatch = dispatch;
		this.cachedSteps = [];
		this.currentlySavingSteps = [];
		this.additionalPersistRequested = false;
	}

	registerSteps(steps) {
		this.cachedSteps.push(...steps);
	}

	async persist() {
		if (any(this.currentlySavingSteps)) {
			this.additionalPersistRequested = true;
			return true;
		}

		this.currentlySavingSteps.push(...this.cachedSteps);
		this.cachedSteps = [];

		try {
			await saveContent(this.documentId, this.currentlySavingSteps, this.dispatch);
		} catch {
			this.cachedSteps.prepend(...this.currentlySavingSteps);
			this.currentlySavingSteps = [];
			return false;
		}

		this.currentlySavingSteps = [];

		if (this.additionalPersistRequested){
			this.additionalPersistRequested = false;
			await this.persist();
		}

		return true;
	}
}

export default DocumentUpdateManager;