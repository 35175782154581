/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const DotDotDot = forwardRef(({ onClick, isActive }, ref) => {
  return (
    <button
      className={isActive ? 'active' : ''}
      ref={ref}
      css={(theme) => css`
        background:url('/images/notemenu.svg') center center no-repeat;
        filter:${theme.filters.icon};
        background-size:15px 15px;
        width:19px; 
        height:2rem;
        padding:0
      `}
      onClick={onClick}
    />
  );
});

DotDotDot.defaultProps = {
  onClick: () => {},
  isActive: false,
};

DotDotDot.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export default DotDotDot;
