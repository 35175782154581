/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CenterColumn from './shared/center-column';
import Nav from './nav';
import Footer from './shared/footer';

const About = () => {
  return (
    <div
      css={css`
        p, ul {
          font-size:20px;
          line-height:30px;
        }
      `}
    >
      <Helmet>
        <title>About Lunette</title>
      </Helmet>
      <Nav />
      <CenterColumn>
        <h1>About Lunette</h1>
        <p>
          Lunette was created in 2023 by&nbsp;
          <a target='_blank' href='https://twitter.com/stephen_corwin' rel='noreferrer'>Stephen Corwin</a>
          {' '}
          and
          {' '}
          <a target='_blank' href='https://kristigrassi.com' rel='noreferrer'>Kristi Grassi</a>
          , a husband and wife team with a shared frustration for existing writing tools.
        </p>
        <hr />
        <p>
          The "aha" moment was stumbling into a particular problem common to almost all writers: that
          {' '}
          <em>ideas arise throughout the writing process, not just at the moment we're ready for them.</em>
        </p>
        <p>
          And what do we do with our ideas when they arise?
          {' '}
          <strong>Well, we scroll to the bottom of the document and jot them down, copy and paste them, rearrange them, and hope we'll remember to come back to them. </strong>
          {' '}
          Naturally, what started as a clean piece of writing eventually devolves into a messy mix of finished passages and half-baked ideas, all jumbled together.
        </p>
        <p>
          We figure if the writing process is fundamentally comprised of two separate parts--ideation and composition--then a word processor designed for writing needs a place for both.
          <br />
          <br />
          <a href='https://lunette.canny.io/feature-requests'>And probably a bunch of other features too</a>
          .
        </p>

        <h2>Philosophy</h2>
        <p>We believe it's the simple things that stand the test of time.  We're here to fascilitate good writing, and our goal is to add to Lunette only the features that do just that. No AI for the sake of AI. No blockchain for the sake of blockchain. No surveys about how you'd like to see Chat GPT incorporated into the product. No constantly changing business models. Just good old fashioned tools that make sense to good old fashioned people.</p>

        <p>
          We are open to any and all feedback on this experiment. You can
          {' '}
          <a href='https://lunette.canny.io/feature-requests'>suggest a feature</a>
          , or just
          {' '}
          <a href='mailto:support@lunette.app'>shoot us an email and say hi.</a>
        </p>

        <p className='margin-bottom-more'>All the best, and thanks for checking out Lunette!</p>
      </CenterColumn>
      <Footer />
    </div>
  );
};

export default About;
