/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

const ListingTable = ({ children, highlightOnHover, className }) => {
  return (
    <table
      className={className}
      css={(theme) => css`
				tbody {
					tr {
						${highlightOnHover && `
							&:hover {
								background-color:${theme.colors.highlight};
								cursor:pointer;
							}
						`}
					}
				}
				tr {

	        td {
	          padding:1rem 0;
	          border-bottom:1px solid ${theme.colors.border};

	          &:first-child {
	            padding-left:0.5rem;
	          }

	          a {
							color:${theme.colors.text};

	            &:first-of-type {
	              font-weight:bold;
	              text-decoration:none;
	              color:${theme.colors.text};
	            }
	          }
	        }
				}
      `}
    >
      {children}
    </table>
  );
};

ListingTable.defaultProps = {
  highlightOnHover: true,
};

ListingTable.propTypes = {
  highlightOnHover: PropTypes.bool,
};

export default ListingTable;
