import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Blockquote from '@tiptap/extension-blockquote';
import BulletList from '@tiptap/extension-bullet-list';
import Heading from '@tiptap/extension-heading';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import ListItem from '@tiptap/extension-list-item';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import DropCursor from '@tiptap/extension-dropcursor';
import History from '@tiptap/extension-history';
import OrderedList from '@tiptap/extension-ordered-list';
import Link from '@tiptap/extension-link';

export default [
  Document,
  Paragraph,
  Text,
  Blockquote,
  BulletList,
  OrderedList,
  Link.configure({
    autolink: true,
    linkOnPaste: true,
    openOnClick: false,
  }),
  Heading.configure({
    levels: [1, 2],
  }),
  HorizontalRule,
  ListItem,
  Bold,
  Italic,
  DropCursor,
  History,
];
