/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import react from 'react';
import PropTypes from 'prop-types';

const CenterColumn = ({ children }) => {
  return (
    <div
      css={(theme) => css`
        margin:50px auto 0 auto;
        width:100%;
        max-width:700px;
        padding:0 1rem;
        box-sizing:border-box;

        @media(max-width:${theme.config.mobileBreakpoint}) {
          margin:25px auto 0 auto;  
        }
        
        h1 {
          margin-bottom:25px;
        }

        hr {
          margin-bottom:25px;
        }

        table {
          width:100%;
          border-spacing:0;
          border-collapse:collapse;
        }

        th {
          text-align:left;
          color:${theme.colors.text2};
          padding-bottom:10px;
          font-weight:normal;
        }
	    `}
    >
      {children}
    </div>
  );
};

export default CenterColumn;
