import ls from 'local-storage';

export const on401 = () => {
  if(ls('currentUser')) {
    ls('currentUser', null);
    alert('You are no longer logged in');
    window.location = '/';
  }
  
};

export default {
  on401,
};
