/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import CenterColumn from './shared/center-column';
import { resendVerificationEmail } from '../actions/app-actions';
import LogoutForm from './logout-form';

const ConfirmEmail = () => {
  const doResendVerificationEmail = () => {
    resendVerificationEmail()
      .then((res) => {
        if (res.status === 200) {
          toast('Verification email sent');
        }
      });
  };

  return (
    <>
      <nav
        css={css`
        text-align:right;
        padding:2rem;
      `}
      >
        <LogoutForm />
      </nav>
      <CenterColumn>
        <div
          css={css`
            text-align:center;
          `}
        >
          <h1>Confirm your email</h1>
          <p>Click the link in the email we sent to confirm your account.</p>
          <p>
            Didn't get a link?&nbsp;
            <button
              type='button'
              className='btn-link'
              onClick={doResendVerificationEmail}
            >
              Resend verification email
            </button>
          </p>
        </div>
      </CenterColumn>
    </>
  );
};

export default ConfirmEmail;
