import { reject, sortBy, findWhere } from 'underscore';

const editorReducer = (state, action) => {
	switch (action.type) {
    case 'chunksLoaded':
      return {
        ...state,
        chunks: action.chunks,
      };
    case 'chunkCreated':
    	return {
    		...state,
    		chunks: sortBy([action.newChunk, ...state.chunks], c => c.position)
    	};
    case 'chunkDeleted':
    	return {
    		...state,
    		chunks: reject(state.chunks, c => {
		      return c.id === action.chunkId;
		    })
    	};
    case 'chunkReordered':
      return {
        ...state,
        chunks: reorderChunks(state.chunks, action.chunkId, action.newPosition),
      }
    case 'saveStarted':
      return {
        ...state,
        saving: true,
      };
    case 'saved':
      return {
        ...state,
        saving: false,
      };
    default:
      throw new Error(`Unhandled action type ${action.type}`);
  }
}

const reorderChunks = (chunks, chunkId, newPosition) => {
  const oldPosition = findWhere(chunks, { id: chunkId }).position;

  if (newPosition > oldPosition) {
    chunks.forEach(c => {
      if (c.position > oldPosition && c.position <= newPosition) {
        c.position--;
      }
    });
  } else if (newPosition < oldPosition) {
    chunks.forEach(c => {
      if (c.position < oldPosition && c.position >= newPosition) {
        c.position++;
      }
    });
  }

  const chunkForUpdate = findWhere(chunks, { id: chunkId });
  chunkForUpdate.position = newPosition;

  return chunks;
};

export default editorReducer;