const theme = (t = 'light') => {
  return {
    config: {
      mobileBreakpoint: '620px',
      columnWidth1:'750px',
      columnWidth2:'1200px',
    },
    colors: {
      alert: 'red',
      background: t === 'light' ? '#FFF' : '#151515',
      text: t === 'light' ? '#292929' : '#fff',
      text2: t === 'light' ? 'rgba(41, 41, 41, 0.5)' : 'rgba(255, 255, 255, 0.5)',
      text3: t === 'light' ? 'rgba(41, 41, 41, 0.8)' : 'rgba(255, 255, 255, 0.8)',
      placeholder: 'rgba(0, 0, 0, 0.25)',
      one: t === 'light' ? '#4B706F' : '#FFF',
      highlight: t === 'light' ? '#F9FAFA' : '#282828',
      border: t === 'light' ? 'rgba(41, 41, 41, 0.2)' : 'rgba(255, 255, 255, 0.1)',
      black: t === 'light' ? '#000' : '#FFF',
    },
    filters: {
      icon: t === 'light' ? 'none' : 'invert(100%) sepia(0%) saturate(0%) hue-rotate(111deg) brightness(104%) contrast(101%);',
    },
    fonts: {
      serif: "'Courier New', sans-serif",
      sansSerif: "'Satoshi', sans-serif",
    },
    fontSizes: {
      base: '14px',
    },
  };
};

export default theme;
