/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SaveStatus from './save-status';
import DownloadButton from './download-button';

const Toolbar = ({
  editor,
  saving,
}) => {
  if (!editor) { return; }

  const onToggleBold = () => {
    editor.chain().focus().toggleBold().run();
  };

  const onToggleItalic = () => {
    editor.chain().focus().toggleItalic().run();
  };

  const onToggleH1 = () => {
    editor.chain().focus().toggleHeading({ level: 1 }).run();
  };

  const onToggleH2 = () => {
    editor.chain().focus().toggleHeading({ level: 2 }).run();
  };

  const onToggleBullets = () => {
    editor.chain().focus().toggleBulletList().run();
  };

  const onToggleOrderedList = () => {
    editor.chain().focus().toggleOrderedList().run();
  };

  const styleFor = (format, opts = {}) => {
    return editor.isActive(format, opts) ? {
      background: 'rgba(41, 41, 41, 0.7)',
      borderRadius: '5px',
      color: '#fff',
    } : {};
  };

  return (
    <div
      css={(theme) => css`
        padding-bottom:1rem;
        color:${theme.colors.text2};
        font-family: ${theme.fonts.sansSerif};

        * {
          font-size:14px;
        }

        button, a.button {
          padding:2px 5px;
          font-size:14px
        }
      `}
    >
      <div
        css={(theme) => css`
          display:flex;
          margin:0 auto;
          max-width:650px;

          @media(max-width:${theme.config.mobileBreakpoint}) {
            position:absolute;
            right:1rem;
            top:1rem;
          }
        `}
      >
        <ul
          css={(theme) => css`
            @media(max-width:${theme.config.mobileBreakpoint}) {
              display:none;
            }
          
            padding:0;
            margin-top:0;
            margin-bottom:0;

            li {
              display:inline-block;
              margin-right:1rem;
              background-color:${theme.colors.background};
            }
         `}
        >
          <li>
            <button
              type='button'
              onClick={onToggleBold}
              style={styleFor('bold')}
            >
              Bold
            </button>
          </li>
          <li>
            <button
              type='button'
              onClick={onToggleItalic}
              style={styleFor('italic')}
            >
              Italic
            </button>
          </li>
          <li>
            <button
              type='button'
              onClick={onToggleH1}
              style={styleFor('heading', { level: 1 })}
            >
              h1
            </button>
          </li>
          <li>
            <button
              type='button'
              onClick={onToggleH2}
              style={styleFor('heading', { level: 2 })}
            >
              h2
            </button>
          </li>
          <li>
            <button
              type='button'
              onClick={onToggleBullets}
              style={styleFor('bulletList')}
            >
              Bullets
            </button>
          </li>
          <li>
            <button
              type='button'
              onClick={onToggleOrderedList}
              style={styleFor('orderedList')}
            >
              Ordered list
            </button>
          </li>
        </ul>

        <div
          css={css`
            margin-left:3rem;
            display:flex;
            align-items:center;
          `}
        >
          <DownloadButton
            editor={editor}
          />

          <SaveStatus
            saving={saving}
          />
        </div>

        <Link
          to='backups'
          className='margin-left button'
        >
          Version history
        </Link>

      </div>

    </div>
  );
};

Toolbar.propTypes = {
  saving: PropTypes.bool.isRequired,
  editor: PropTypes.shape({
    isActive: PropTypes.func.isRequired,
    chain: PropTypes.func.isRequired,
  }),
};

export default Toolbar;
