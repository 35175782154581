/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';


import React, { useReducer, useEffect } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ls from 'local-storage';
import { ThemeProvider } from '@emotion/react';

import './assets/fonts/Satoshi-Regular.woff2';
import './assets/fonts/Satoshi-Bold.woff2';
import './styles/app.scss';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ConfirmEmail from './components/confirm-email';
import DoVerifyEmail from './components/do-verify-email';
import DocumentListing from './components/document-listing';
import Home from './components/home';
import LoginForm from './components/login-form';
import SignupForm from './components/signup-form';
import ForgotPassword from './components/forgot-password';
import SubscriptionManager from './components/subscription-manager';
import About from './components/about';
import Editor from './components/editor';
import Backups from './components/editor/backups';
import Backup from './components/editor/backup';
import UpgradeSuccess from './components/subscription-manager/upgrade-success';
import UserContext from './contexts/user-context';
import AppDispatchContext from './contexts/dispatch-context';
import { loadContent, getCurrentUser } from './actions/app-actions';
import appReducer from './reducers/app-reducer';
import theme from './styles/theme';
import ResetPassword from './components/reset-password';
import ErrorBoundary from './components/shared/error-boundary';
import Privacy from './components/privacy';
import Terms from './components/terms';
import Loader from './components/shared/loader';

const App = () => {
  const [state, dispatch] = useReducer(appReducer, {
    initialUserLoaded: false,
    documents: null,
  });

  // Refresh user details
  useEffect(() => {
    getCurrentUser()
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: 'initialUserLoaded',
          user: res.user,
        });

        ls('currentUser', res.user);
      });
  }, []);

  useEffect(() => {
    if (!state.user) { return; }

    loadContent()
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: 'contentLoaded',
          content: res.content,
        });
      });
  }, [state.user]);

  if (!state.initialUserLoaded) {
    return (
      <Loader>
        <Helmet>
          <title>Loading Lunette...</title>
        </Helmet>
      </Loader>
    );
  }

  return (
    <ErrorBoundary>
      <Helmet>
        <title>Lunette</title>
      </Helmet>

      <ThemeProvider
        theme={theme(state.user?.darkModeEnabled ? 'dark' : 'light')}
      >
        <Global
          styles={(t) => css`
            body {
              background:${t.colors.background};
              color:${t.colors.text};
              font-family:${t.fonts.sansSerif};
            }

            button, a.button {
              color:${t.colors.text2};

              &.btn-secondary {
                border:1px solid ${t.colors.one};
                color:${t.colors.black};
              }
            }

            a, button.btn-link {
              color:${t.colors.one};
            }

            input {
              &::placeholder {
                color:${t.colors.placeholder};
              }
            }
          `}
        />
        
        <ToastContainer
          position='bottom-center'
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
          transition={Slide}
        />
        <UserContext.Provider value={state.user}>
          <AppDispatchContext.Provider value={dispatch}>
            <Router>
              <Routes>
                <Route
                  path='/about'
                  element={<About />}
                />

                <Route
                  path='/privacy_policy'
                  element={<Privacy />}
                />

                <Route
                  path='/terms_of_service'
                  element={<Terms />}
                />

                {!state.user && (
                  <>
                    <Route
                      path='/'
                      element={<Home />}
                    />

                    <Route
                      path='/login'
                      element={<LoginForm />}
                    />

                    <Route
                      path='/signup'
                      element={<SignupForm />}
                    />

                    <Route
                      path='/forgot_password'
                      element={<ForgotPassword />}
                    />

                    <Route
                      path='/reset_password/:token'
                      element={<ResetPassword />}
                    />
                  </>
                )}

                {!state.user || (state.user && !state.user.emailVerifiedAt)}
                <Route
                  element={<DoVerifyEmail />}
                  path='/verify_email/:emailVerificationCode'
                />

                {state.user && !state.user.emailVerifiedAt && (
                  <>
                    <Route
                      path='/verify_email'
                      element={<ConfirmEmail />}
                    />

                    <Route
                      path='*'
                      element={<Navigate to='/verify_email' replace />}
                    />
                  </>
                )}

                {state.user && state.user.emailVerifiedAt && (
                  <>
                    <Route
                      path='/'
                      element={<Navigate to='/documents' replace />}
                    />

                    <Route
                      path='/subscription/upgrade/success'
                      element={<UpgradeSuccess />}
                    />

                    <Route
                      path='/subscription/upgrade'
                      element={<SubscriptionManager suggestUpgrade />}
                    />

                    <Route
                      path='/subscription'
                      element={<SubscriptionManager />}
                    />

                    <Route
                      path='/documents'
                      element={<DocumentListing documents={state.documents} />}
                    />

                    <Route path='/documents/:documentId'>
                      <Route
                        path='backups'
                      >
                        <Route
                          path=':snapshotId'
                          element={<Backup />}
                        />

                        <Route
                          path=''
                          element={<Backups />}
                        />
                      </Route>

                      <Route
                        path=''
                        element={<Editor />}
                      />
                    </Route>
                  </>
                )}

                <Route
                  path='*'
                  element={<Navigate to='/' replace />}
                />
              </Routes>
            </Router>
          </AppDispatchContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
