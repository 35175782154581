/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from '../assets/images/lunette-logo.svg';

const Nav = () => {
  return (
    <nav
      css={css`
        height: 115px;
        display:flex;
        align-items:center;
        padding:2rem;
        box-sizing:border-box;

        div {
          &:first-of-type {
            flex:1;

            img {
              height: 25px;
            }
          }

          &:last-of-type {

          }
        }
      `}
    >
      <div>
        <Link to='/'>
          <img src={logo} />
        </Link>
      </div>

      <div
        css={css`
          @media (max-width: 420px) {
            a {
              background:none !important;
              padding:0 !important;
              border:none !important;
              color:#4B706F !important;
            }
          }
        `}
      >
        <Link className='button btn-secondary margin-right' to='/login'>
          Log in
        </Link>

        <Link className='button btn-primary' to='/signup'>
          Sign up
        </Link>
      </div>
    </nav>
  );
};

export default Nav;
