/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  isNull, any, filter, isEmpty,
} from 'underscore';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import truncate from 'truncate';
import { toast } from 'react-toastify';
import Navbar from './document-listing/navbar';
import userContext from '../contexts/user-context';
import AppDispatchContext from '../contexts/dispatch-context';
import { createDocument, deleteDocument } from '../actions/menu-actions';
import MainNav from './main-nav';
import CenterColumn from './shared/center-column';
import ListingTable from './shared/listing-table';
import OptionsMenu from './chunk/options-menu';
import MenuItem from './chunk/options-menu/menu/item';

const DocumentListing = ({ documents }) => {
  const dispatch = useContext(AppDispatchContext);
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');

  const user = useContext(userContext);
  if (!user) {
    return <Navigate to='/' replace />;
  }

  const messageStyle = css`
    text-align:center;
    font-style:italic;
    padding:50px 0;
  `;

  const documentsToShow = useMemo(() => {
    if (!documents) { return []; }

    if (!searchQuery || searchQuery.length === 0) {
      return documents;
    }
    return filter(documents, (doc) => {
      return doc.title && doc.title.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [documents, searchQuery]);

  const renderDocuments = () => {
    if (!any(documents)) {
      return (
        <tr>
          <td
            colspan='2'
            css={messageStyle}
          >
            You haven't created any documents yet. Once you do, they will display here.
          </td>
        </tr>
      );
    }

    return documentsToShow.map((doc) => {
      const docUrl = `/documents/${doc.id}`;

      return (
        <tr
          key={doc.id}
          onClick={() => navigate(docUrl)}
        >
          <td>
            <Link to={docUrl}>
              {truncate(doc.title, 50) || 'Untitled document'}
            </Link>
          </td>

          <td>
            { new Date(doc.updatedAt).toLocaleDateString(
              'en-us',
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              },
            )}
          </td>

          <td>
            <div
              css={css`
                text-align:right;
                padding-right:0.5rem;
              `}
            >
              <OptionsMenu>
                <MenuItem
                  onClick={() => onDeleteDocClick(doc.id)}
                >
                  Delete
                </MenuItem>
              </OptionsMenu>
            </div>
          </td>
        </tr>
      );
    });
  };

  const onDeleteDocClick = (documentId) => {
    const shouldDelete = confirm('Delete this document?');
    if (!shouldDelete) { return; }

    deleteDocument(documentId)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: 'documentDeleted',
            documentId,
          });

          toast('Document deleted');
        }
      });
  };

  const onNewButtonClick = async () => {
    const res = await createDocument();
    const doc = await res.json();
    dispatch({
      type: 'documentCreated',
      document: doc,
    });

    navigate(`/documents/${doc.id}`);
  };

  if (isNull(documents)) { return null; }

  const isAtLimit = () => {
    return documents.length >= 2 && user.plan !== 'premium';
  };

  const onDocSearchChanged = (e) => {
    setSearchQuery(e.currentTarget.value);
  };
  return (
    <div
      css={css`
        margin-bottom:100px;
      `}
    >
      <MainNav />
      <CenterColumn>
        <h1>
          Documents
        </h1>

        <Navbar
          isAtLimit={isAtLimit()}
          onNewButtonClick={onNewButtonClick}
          onDocSearchChanged={onDocSearchChanged}
        />

        <ListingTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Last edited</th>
            </tr>
          </thead>

          <tbody>
            {renderDocuments()}
          </tbody>
        </ListingTable>

        {isAtLimit() && (
          <div
            css={messageStyle}
          >
            You&apos;ve reached your 2 document limit.
            {' '}
            <Link to='/subscription/upgrade'>Upgrade to premium</Link>
            {' '}
            to create a new document.
          </div>
        )}
      </CenterColumn>

    </div>
  );
};

DocumentListing.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({})),
};

export default DocumentListing;
