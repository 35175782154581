import fetchWrap from '../lib/fetch-wrap';

export const saveContent = (documentId, steps, dispatch) => {
  const stepsAsJSON = steps.map(s => s.toJSON());

  dispatch({
    type: 'saveStarted'
  });

  return fetchWrap.post(`/api/documents/${documentId}`, {
    body: JSON.stringify({
      steps: stepsAsJSON,
    }),
  }).then(res => {
    dispatch({
      type: 'saved',
    });
  });
};

export const updateChunk = (chunkId, editor) => {
  const json = editor.getJSON();
  fetchWrap.post(`/api/chunks/${chunkId}`, {
    body: JSON.stringify({
      content: json,
    }),
  });
};

export const updateTitle = (documentId, newTitle) => {
  fetchWrap.post(`/api/documents/${documentId}`, {
    body: JSON.stringify({
      title: newTitle,
    }),
  })
};

export const loadContent = (editor, documentId) => {
  return fetchWrap.get(`/api/documents/${documentId}`);
};

export const loadSnapshots = (documentId) => {
  const url = `/api/documents/${documentId}/snapshots`;
  return fetchWrap.get(url);
};

export const loadSnapshot = (snapshotId) => {
  const url = `/api/snapshots/${snapshotId}`;
  return fetchWrap.get(url);
};

export const createChunk = (documentId, chunkAttrs = {}) => {
  const url = '/api/chunks/create';
  return fetchWrap.post(url, {
    body: JSON.stringify({
      documentId,
      ...chunkAttrs
    }),
  });
};

export const reorderChunk = (chunkId, newPosition) => {
  const url = `/api/chunks/${chunkId}`;
  return fetchWrap.post(url, {
    body: JSON.stringify({
      chunkId,
      position: newPosition,
    }),
  });
};

export const duplicateChunk = (chunkId) => {
  const url = `/api/chunks/${chunkId}/duplicate`;
  return fetchWrap.post(url);
};

export const deleteChunk = (chunkId) => {
  const url = `/api/chunks/${chunkId}`;
  return fetchWrap.delete(url);
};
