/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import CenterForm from './shared/center-form';
import { resetPassword } from '../actions/app-actions';
import BackArrow from './shared/back-arrow';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    resetPassword(email)
      .then((res) => {
        if (res.status === 200) {
          setEmail('');
          toast('Reset instructions sent');
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>Reset password</title>
      </Helmet>

      <BackArrow
        url='/login'
        label='Login'
      />

      <CenterForm>
        <h1>Reset password</h1>
        <form
          onSubmit={onSubmit}
        >
          <label>
            <span>Email address</span>
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              autoComplete='username'
              required
              autoFocus
            />
          </label>

          <button
            className='btn-primary'
            type='submit'
            css={css`
              margin-top:1rem;
            `}
          >
            Submit
          </button>
        </form>
      </CenterForm>
    </>
  );
};

export default ForgotPassword;
