import React from 'react';
import { Helmet } from 'react-helmet';
import Nav from './nav';
import CenterColumn from './shared/center-column';
import Footer from './shared/footer';

const Terms = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Service</title>
      </Helmet>
      <Nav />
      <CenterColumn>
        <h1>Terms of Service</h1>
        <p>Welcome to Lunette! Please read these Terms of Service carefully before using our service.</p>
        <h2>1. Agreement to Terms</h2>
        <p>By using Lunette, you agree to these Terms of Service and our Privacy Policy. If you do not agree to these Terms of Service or our Privacy Policy, you may not use Lunette.</p>
        <h2>2. Use of Lunette</h2>
        <p>You may use Lunette only for lawful purposes and in accordance with these Terms of Service. You agree not to use Lunette:</p>
        <ul>
          <li>In any way that violates any applicable federal, state, local, or international law or regulation.</li>
          <li>To impersonate or attempt to impersonate Lunette, a Lunette employee, another user, or any other person or entity.</li>
          <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of Lunette, or which, as determined by us, may harm Lunette or users of Lunette or expose them to liability.</li>
        </ul>
        <h2>3. Intellectual Property Rights</h2>
        <p>Lunette and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by Lunette, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
        <h2>4. User Content</h2>
        <p>Lunette allows you to create, edit, and save documents. You retain ownership of all intellectual property rights in the content you create using Lunette.</p>
        <h2>5. Payment Terms</h2>
        <p>We may offer paid subscriptions for additional features or storage space. Payment terms for such subscriptions will be described on Lunette and may change from time to time.</p>
        <h2>6. Disclaimer of Warranties</h2>
        <p>Lunette is provided "as is" and "as available" without any warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
        <h2>7. Limitation of Liability</h2>
        <p>In no event shall Lunette, its affiliates, or their licensors, service providers, employees, agents, officers, or directors be liable for damages of any kind, under any legal theory, arising out of or in connection with your use, or inability to use, Lunette, including any direct, indirect, special, incidental, consequential, or punitive damages, including but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract, or otherwise, even if foreseeable.</p>
        <p>Your sole remedy for dissatisfaction with Lunette is to stop using it. Some states do not allow the exclusion or limitation of incidental or consequential damages, so the above limitations or exclusions may not apply to you.</p>
      </CenterColumn>

      <Footer />
    </>
  );
};

export default Terms;
