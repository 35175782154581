/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { slide as Menu } from 'react-burger-menu';
import Switch from 'react-switch';
import { Link } from 'react-router-dom';
import { initial } from 'underscore';
import LogoutForm from './logout-form';
import userContext from '../contexts/user-context';
import { toggleDarkMode } from '../actions/app-actions';
import AppDispatchContext from '../contexts/dispatch-context';

const MainNav = () => {
  const {
    plan,
    darkModeEnabled,
  } = useContext(userContext);
  const dispatch = useContext(AppDispatchContext);

  const onToggleDarkMode = (isOn) => {
    toggleDarkMode(isOn)
      .then(async (res) => {
        if (res.ok) {
          const user = await res.json();
          dispatch({
            type: 'userLoaded',
            user,
          });
        }
      });
  };

  const navItems = (
    <>
      <label
        css={css`
          margin-right:2rem;

          span {
            margin-right:0.66rem;
          }
          
          span, div {
            vertical-align:middle;
          }
        `}
      >
        <span>Dark mode</span>
        <Switch
          onChange={onToggleDarkMode}
          checked={darkModeEnabled}
          onColor='#444'
          offColor='#DDD'
          height={14}
          width={28}
          checkedIcon={false}
          uncheckedIcon={false}
        />
      </label>

      <a
        href='https://lunette.canny.io/feature-requests'
        target='_blank'
        rel='noreferrer'
      >
        Suggest a feature
      </a>

      <Link
        to='/subscription/upgrade'
      >
        {plan === 'free' ? (
		      'Upgrade to Premium'
        ) : (
				  'Manage account'
        )}
      </Link>

      <LogoutForm />
    </>
  );

  const theme = useTheme();

  const styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '1.8rem',
      height: '1rem',
      right: '36px',
      top: '36px',
    },
    bmBurgerBars: {
      background: theme.colors.text,
    },
    bmBurgerBarsHover: {
      background: '#a90000',
    },
    bmCrossButton: {
      height: '24px',
      width: '24px',
    },
    bmCross: {
      background: '#bdc3c7',
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
    },
    bmMenu: {
      background: theme.colors.background,
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em',
    },
    bmMorphShape: {
      fill: '#373a47',
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em',
    },
    bmItem: {
      display: 'inline-block',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  };

  return (
    <>
      <nav
        css={(theme) => css`
          text-align:right;
          padding:1rem 2rem;
          
          a {
            text-decoration:none;
            margin-right:20px;
            
            &:hover {
              text-decoration:underline;
            }
          }

          @media(max-width:${theme.config.mobileBreakpoint}) {
            display: none;
            padding:0;
          }
        `}
      >
        {navItems}
      </nav>

      <div
        css={(theme) => css`
          display:none;
          
          @media(max-width:${theme.config.mobileBreakpoint}) {
            display:initial;
          }
        `}
      >
        <Menu
          styles={styles}
          right
          outerContainerId='root'
        >
          {navItems}
        </Menu>
      </div>
    </>
  );
};

export default MainNav;
