import React, { useReducer, createRef } from 'react';
import Menu from './options-menu/menu';
import useCloseIfOutside from '../../hooks/use-close-if-outside';
import DotDotDot from '../shared/dotdotdot';
import MenuDispatchContext from '../../contexts/menu-dispatch-context';

const OptionsMenu = ({
  children,
}) => {
  const menuRef = createRef();

  const dispatchCloseMenu = () => {
    dispatch({
      type: 'closeMenu',
    });
  };

  const anchorRef = useCloseIfOutside(dispatchCloseMenu, {
    containers: [menuRef],
  });

  const reducer = (state, action) => {
    switch (action.type) {
      case 'openMenu':
        return {
          ...state,
          menuOpen: true,
          clickX: action.pageX,
          clickY: action.pageY,
        };
      case 'closeMenu':
        return {
          ...state,
          menuOpen: false,
          clickX: null,
          clickY: null,
        };
      default:
        throw new Error(`Unhandled action type ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    menuOpen: false,
    clickX: null,
    clickY: null,
  });

  const onMenuOpenClick = (e) => {
    e.stopPropagation();

    dispatch({
      type: 'openMenu',
      pageX: e.clientX,
      pageY: e.clientY,
    });
  };

  return (
    <MenuDispatchContext.Provider value={dispatch}>
      <>
        <DotDotDot
          isActive={state.menuOpen}
          ref={anchorRef}
          onClick={onMenuOpenClick}
        />

        {state.menuOpen && (
        <Menu
          ref={menuRef}
          clickX={state.clickX}
          clickY={state.clickY}
        >
          {children}
        </Menu>
        )}
      </>
    </MenuDispatchContext.Provider>
  );
};

export default OptionsMenu;
