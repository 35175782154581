/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { submitEmailVerification } from '../actions/app-actions';
import AppDispatchContext from '../contexts/dispatch-context';
import userContext from '../contexts/user-context';
import loaderImg from '../assets/images/loader.svg';

const DoVerifyEmail = () => {
  const { emailVerificationCode } = useParams();
  const navigate = useNavigate();
  const dispatch = useContext(AppDispatchContext);
  const user = useContext(userContext);

  const handleVerificationResult = (res) => {
    res.json()
      .then(async (jsonRes) => {
        switch (jsonRes.result) {
          case 'alreadyVerified':
            dispatch({
              type: 'userLoaded',
              user: jsonRes.user,
            });
            toast('Account already verified');
            break;
          case 'verified':
            await dispatch({
              type: 'userLoaded',
              user: jsonRes.user,
            });
            toast('Email verified. Enjoy Lunette!');
            break;
          case 'invalid':
            navigate('/verify_email');
            toast('Invalid verification code');
            break;
          default:
            throw new Error('Unhandled verification response');
        }
      });
  };

  useEffect(() => {
    if (!user?.emailVerifiedAt) { return; }

    navigate('/');
  }, [user?.emailVerifiedAt]);

  useEffect(() => {
    submitEmailVerification(emailVerificationCode)
      .then(handleVerificationResult);
  }, []);

  return (
    <span>
      <img
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
        src={loaderImg}
      />
    </span>
  );
};

export default DoVerifyEmail;
