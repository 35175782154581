/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import { Link } from 'react-router-dom';
import Nav from './nav';
import Footer from './shared/footer';

const Home = (props) => {
  return (
    <div
      css={(theme) => css`
				display:flex;
				flex-direction:column;
				width:100%;
				height:100%;

        h2 {
          font-size:36px;
          font-weight:bold;
          font-family:${theme.fonts.sansSerif};
        }
			`}
    >
      <Nav />

      <section
        css={css`
					flex-grow:1;
					padding:2rem;
				`}
      >
        <div
          css={(theme) => css`
          text-align:center;
          
          h1, h2 {
            max-width:${theme.config.columnWidth1};
            margin:auto;
          }
            
          h1 {
            font-weight:900;
            font-size:60px;
            margin:auto;
            line-height:70px;
            margin-bottom:3rem;
          }
          
          h2 {
            font-family:${theme.fonts.sansSerif};
            font-weight:normal;
            font-size:24px;
          }
          
          @media(max-width:${theme.config.mobileBreakpoint}) {
            h1 {
              font-size:40px;
              line-height:40px;
              margin-bottom:1rem;
            }

            h2 {
              font-size:18px;
            }
          }
					`}
        >
          <h1>
            A word processor for people who write
          </h1>
          <h2
            className='margin-bottom-more'
            css={css`
              max-width:550px !important;
            `}
          >
            Writing isn't about formatting, it's about finding <strong>flow</strong> and the <strong>right frame of mind.</strong> Lunette gives just what you need to get there, and nothing more.
          </h2>
          <div>
            <video
              autoPlay
              loop
              css={(theme) => css`
                width:100%;
                max-width:1024px;
                border-radius:10px;
                margin:auto;
                border:1px solid ${theme.colors.border};
              `}
            >
              <source
                src='/video/lunette.mp4'
                type='video/mp4'
              />
              Your browser does not support the video tag.
            </video>
          </div>

          <div
            className='margin-top-more margin-bottom-more'
          >
            <Link
              to='/signup'
              className='button btn-primary bigger'
            >
              Start writing
            </Link>
          </div>
        </div>

      </section>
      <section
        css={(theme) => css`
          border-top:1px solid ${theme.colors.border};
          border-bottom:1px solid ${theme.colors.border};
          padding:5rem 0;
          max-width:${theme.config.columnWidth2};
          margin:auto;
          width:100%;
          display:block;

          @media(max-width:${theme.config.mobileBreakpoint}) {
            padding:3rem 0;
          }

          h2 {
            margin-top:0;
          }
          
          p {
            font-size:20px;
          }
        `}
      >
        <div
          css={(theme) => css`
            max-width:${theme.config.columnWidth1};
            margin:auto;
            
            p {
              line-height:1.7em;
            }

            @media(max-width:${theme.config.mobileBreakpoint}) {
              padding:1rem;

              p {
                font-size:1rem;
              }
            }
          `}
        >
          <h2>What makes Lunette different?</h2>
          <p>There’s no shortage of cloud-based word processors out there. We should know--we’ve tried them all. What we learned is that while most of them are perfectly capable of putting text on a page, none of them were designed with the writing process in mind.</p>
          <p>Some are business tools, jam-packed with features for notes and memos and contracts. Others are more focused on technology than on writing, or are beholden to sketchy business models that compromise user interests.</p>
          <p>What we really wanted was a product that recognizes writing as a unique craft with unique requirements, and which incorporates only the practical and aesthetic features that lead to better writing. That’s what we built, and we hope you’ll love using it as much as we do.</p>
          <p>- Stephen and Kristi, the pair behind Lunette</p>
        </div>
      </section>

      <section
        css={(theme) => css`
          text-align:center;

          h2 {
            margin-bottom:2em;
            margin-top:5rem;
          }

          dl {
            max-width:${theme.config.columnWidth2};
            margin:auto;

            div {
              max-width:30%;
              min-width:300px;
              display:inline-block;
              font-size:1.3em;
              height:200px;
              vertical-align:top;
              padding:0 1em;

              dt {
                font-weight:bold;
                margin-bottom:1em;
              }

              dd {
                margin-left:0;
              }
            }
          }
        `}
      >
        <h2>Why people love Lunette</h2>
        <dl>
          <div>
            <dt>Focused on writing</dt>
            <dd>No dark patterns, no vested interests, no ulterior motives. Our north star is better writing.</dd>
          </div>

          <div>
            <dt>Run by real people</dt>
            <dd>No corporate wall here. We’re just a husband and wife team. Don’t believe it? Try <a href='mailto:support@lunette.app'>shooting us an email</a>.</dd>
          </div>

          <div>
            <dt>Version history</dt>
            <dd>Because we’ve all had that “what happened to that really important section that was here yesterday?” moment.</dd>
          </div>

          <div>
            <dt>Good vibrations</dt>
            <dd>There’s a reason writer's retreats are in cozy lakeside cottages and not in business parks with drop-panel ceilings. Aesthetics matter. </dd>
          </div>

          <div>
            <dt>Dark mode</dt>
            <dd>Because the wee hours are the good hours.</dd>
          </div>

          <div>
            <dt>Distraction-free</dt>
            <dd>We value simplicity. Too many features and it all just becomes clutter.</dd>
          </div>

          <div>
            <dt>Intentional design</dt>
            <dd>We don’t build things just to keep our engineering team busy. In fact, we don’t have an engineering team, it’s just Stephen.</dd>
          </div>

          <div>
            <dt>AI-free</dt>
            <dd>It’s more important than ever to defend writing with integrity. There’s a time and a place for AI, but this is isn’t it.</dd>
          </div>

          <div>
            <dt>User-driven</dt>
            <dd>We want to know how we can help you be a better writer. Feature suggestions are always welcome.</dd>
          </div>

        </dl>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
