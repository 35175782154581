import React from 'react';
import { Helmet } from 'react-helmet';
import Nav from './nav';
import CenterColumn from './shared/center-column';
import Footer from './shared/footer';

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <Nav />
      <CenterColumn>
        <h1>Privacy Policy</h1>
        <p>Lunette is committed to protecting the privacy and security of our users' personal information. This Privacy Policy describes how we collect, use, and disclose personal information when you use Lunette.</p>
        <h2>Information We Collect</h2>
        <p>We may collect the following types of personal information from you when you use our Lunette service:</p>
        <ul>
          <li>Your name and email address when you create an account.</li>
          <li>Your document contents and metadata when you create, edit, or save a document.</li>
          <li>Your IP address and browser information when you access Lunette.</li>
        </ul>
        <h2>Use of Information</h2>
        <p>We use the personal information we collect to:</p>
        <ul>
          <li>Provide you with access to Lunette.</li>
          <li>Improve Lunette's functionality and performance.</li>
          <li>Respond to your support requests.</li>
          <li>Comply with legal obligations and protect our rights.</li>
        </ul>
        <h2>Disclosure of Information</h2>
        <p>We may disclose personal information to third-party service providers who assist us in providing our service, such as hosting and data storage providers. We may also disclose personal information to comply with legal requirements, enforce our policies, or protect our rights.</p>
        <h2>Data Security</h2>
        <p>We take reasonable measures to protect your personal information from unauthorized access, use, disclosure, and destruction. We store all data on secure servers and restrict access to authorized personnel only.</p>
        <h2>Data Retention</h2>
        <p>We retain personal information as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required or permitted by law.</p>
        <h2>Your Choices</h2>
        <p>You may opt-out of receiving promotional emails from us by following the unsubscribe link in the email. You may also delete your account and all associated personal information by contacting us at the email address below.</p>
        <h2>Updates to Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our data practices. We will notify you of any material changes to this Privacy Policy by email or by posting a notice on Lunette.</p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at
          <a href='mailto:support@lunette.app'>support@lunette.app</a>
          .
        </p>
      </CenterColumn>
      <Footer />
    </>
  );
};

export default Privacy;
