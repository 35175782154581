/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import loaderImg from '../../assets/images/loader.svg';

const Loader = () => {
  return (
    <div
      css={(theme) => css`
        text-align:center;
      `}
    >
      <img
        css={(theme) => css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
        src={loaderImg}
      />
    </div>
  );
};

export default Loader;
