/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '../../actions/app-actions';

import loaderImg from '../../assets/images/loader.svg';

const UpgradeSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    pollForUpgradeCompleted();
  }, []);

  const pollForUpgradeCompleted = () => {
    getCurrentUser()
      .then((res) => res.json())
      .then((parsed) => {
        if (parsed.user.plan === 'premium') {
          navigate('/subscription');
          return;
        }

        setTimeout(pollForUpgradeCompleted, 1000);
      });
  };

  return (
    <img
      css={css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter:${theme.filters.icon};
      `}
      src={loaderImg}
    />
  );
};

export default UpgradeSuccess;
