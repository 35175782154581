/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import PropTypes from 'prop-types';

const Navbar = ({
  isAtLimit,
  onNewButtonClick,
  onDocSearchChanged,
}) => {
  return (
    <div
      css={css`
				border-bottom:1px solid rgba(41, 41, 41, 0.2);
        margin-bottom:30px;
        padding-bottom:10px;
			`}
    >
      <button
        id='newdocbutton'
        type='button'
        className='btn-circle'
        css={css`
					padding:0;
				`}
        onClick={onNewButtonClick}
        disabled={isAtLimit}
        title={isAtLimit ? 'Upgrade to premium to create more documents' : 'Start a new document'}
      >
        Create new
      </button>

      <input
        type='text'
        css={(theme) => css`
      		background:url('/images/search.svg') left center no-repeat;
          filter:${theme.filters.icon};
      		background-size:16px 16px;
      		padding-left:20px;
      		margin-left:20px;
      	`}
        placeholder='Search documents'
        onKeyUp={onDocSearchChanged}
      />
    </div>
  );
};

export default Navbar;
