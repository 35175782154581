/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { forwardRef } from 'react';

const Menu = forwardRef(({
  clickX,
  clickY,
  children,
  className,
  onMenuItemClick,
}, ref) => {
  if (!clickX || !clickY) { return; }

  return (
    <div
      ref={ref}
      className={className}
      css={(theme) => css`
        background-color:${theme.colors.background};;
        box-shadow: 0px 0px 6px rgba(41, 41, 41, 0.1);
        border: 1px solid rgba(41, 41, 41, 0.3);
				border-radius: 2px;
        position:fixed;
        top: ${clickY}px;
        left: ${clickX}px;
        transform: translateX(-100%);
        z-index:10;

        ul {
        	margin:0;
        	list-style:none;
        	padding:0;

        	li:last-child button {
        		padding-bottom:1rem;
        	}
        }

        button {
        	text-align:left;
        	padding:1rem 1rem 0 1rem;
        	margin:0;
        	min-width:100px;
        	color: ${theme.colors.text2};
        }
			`}
    >
      <ul>
        {children}
      </ul>
    </div>
  );
});

Menu.defaultProps = {
  className: '',
};

export default Menu;
