/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import PropTypes from 'prop-types';

const CenterForm = ({ children }) => {
  return (
    <div
      css={(theme) => css`
        margin:60px auto;
        width:275px;

        label {
          color:${theme.colors.text2};
          font-size:$base-font-size;
          margin-bottom:1rem;
          display:block;

          input {
            margin-top:5px;
            width:100%;
            border: 1px solid ${theme.colors.text2};
            outline:none;
            padding:10px;
          }
        }

        h1 {
          font-family:${theme.fonts.sansSerif};
          margin-bottom:40px;
        }
      `}
    >
      {children}
    </div>
  );
};

export default CenterForm;
