import {  reject } from 'underscore';

function reducer(state, action) {
  switch (action.type) {
    case 'initialUserLoaded':
      return {
        ...state,
        user: action.user,
        initialUserLoaded: true,
      };
    case 'userLoaded':
      return {
        ...state,
        user: action.user,
      };
    case 'contentLoaded':
      return {
        ...state,
        documents: action.content.documents,
      };
    case 'userLoggedOut':
      return {
        ...state,
        user: null,
      };
    case 'userLoggedIn':
      return {
        ...state,
        user: action.user,
      };
    case 'documentCreated':
      return {
        ...state,
        documents: [action.document, ...state.documents],
      };
    case 'documentDeleted':
      return {
        ...state,
        documents: reject(state.documents, (d) => d.id === action.documentId),
      };
    default:
      throw new Error(`Unhandled action type ${action.type}`);
  }
}

export default reducer;
