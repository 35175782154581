import fetchWrap from '../lib/fetch-wrap';

export const createDocument = () => {
  return fetchWrap.post('/api/documents/new');
};

export const deleteDocument = (documentId) => {
  return fetchWrap.delete(`/api/documents/${documentId}`);
};

export const login = (email, password) => fetchWrap.post('/api/session/login', {
  body: JSON.stringify({
    email,
    password,
  }),
});

export const logout = () => fetchWrap.delete('/api/session/logout');

export const signup = (email, password) => fetchWrap.post('/api/session/signup', {
  body: JSON.stringify({
    email,
    password,
  }),
});
