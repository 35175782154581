/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useState } from 'react';

const SubscriptionToggleOption = ({
  isToggled,
  isCurrent,
  onClick,
  name,
  desc,
  price,
}) => {
  const highlightStyle = css`
    border:2px solid #4B706F;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  `;

  return (
    <button
      type='button'
      onClick={onClick}
      css={(theme) => css`
        	flex:1;
        	margin:0 0.5rem;
        	text-align:left;
        	background-color: ${theme.colors.background};
        	border:1px solid ${theme.colors.border};
        	padding:1.5rem 2.5rem;
        	border-radius:10px;
          ${isToggled ? highlightStyle : ''}
        `}
    >
      <div
        css={(t) => css`
	        	font-size:18px;
	        	font-weight:bold;
	        	color:${t.colors.text};
	        	margin-bottom:1rem;
        	`}
      >
        {name}
        { isCurrent && (
        <span
          css={(theme) => css`
	        			color: ${theme.colors.one};
	        			margin-left: 1rem;
	        			font-weight:normal;
	        		`}
        >
          Current plan
        </span>
        	)}
      </div>
      <div
        css={(theme) => css`
	        	font-size:14px;
	        	color:${theme.colors.text2};
	        	margin-bottom:5px;
        	`}
      >
        {desc}
      </div>
      <div
        css={(t) => css`
	        	font-size:14px;
	        	font-weight: bold;
	        	color:${t.colors.text};
        	`}
      >
        {price}
      </div>
    </button>
  );
};

export default SubscriptionToggleOption;
