/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, {
  useRef, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import ls from 'local-storage';
import { Helmet } from 'react-helmet';
import { login } from '../actions/menu-actions';
import UserContext from '../contexts/user-context';
import AppDispatchContext from '../contexts/dispatch-context';
import InlineError from './shared/inline-error';
import CenterForm from './shared/center-form';

const LoginForm = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useContext(AppDispatchContext);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (user) {
      navigate('/', { replace: true });
    }
  }, [user]);

  const emailField = useRef();
  const passwordField = useRef();

  const onLoginSubmit = (e) => {
    e.preventDefault();
    onLoginClick();
  };

  const validateSubmit = () => {
    const email = emailField.current.value;
    const password = passwordField.current.value;

    setErrors([]);

    const newErrors = [];

    if (!email) {
      newErrors.push('You must enter an email');
    }

    if (!password) {
      newErrors.push('You must enter a password');
    }

    setErrors(newErrors);

    if (newErrors.length > 0) {
      return false;
    }

    return true;
  };

  const onLoginClick = () => {
    if (!validateSubmit()) { return; }

    const email = emailField.current.value;
    const password = passwordField.current.value;

    login(email, password).then((res) => {
      if (res.status !== 302) {
        setErrors(['Unable to login. Check your username and password.']);
        return;
      }

      res.json().then((res) => {
        ls('currentUser', res.user);
        dispatch({
          type: 'userLoggedIn',
          user: res.user,
        });
        navigate('/documents');
      });
    });
  };

  return (
    <>
      <Helmet>
        <title>Log in</title>
      </Helmet>
      <CenterForm>
        <h1>Log in</h1>

        { errors && (
          <InlineError className='margin-bottom'>
            {errors.join(', ')}
          </InlineError>
        )}

        <form
          onSubmit={onLoginSubmit}
        >
          <label>
            <span>Email address</span>
            <input
              ref={emailField}
              id='email'
              name='email'
              type='email'
              autoComplete='username'
              required
              autoFocus
            />
          </label>

          <label>
            <span>Password</span>
            <input
              ref={passwordField}
              id='current-password'
              name='password'
              type='password'
              autoComplete='current-password'
              required
            />
          </label>

          <button
            className='btn-primary'
            css={css`
              margin-top:1rem;
            `}
          >
            Log in
          </button>
        </form>

        <div
          css={css`
            margin-top:20px;
          `}
        >
          <a href='/signup'>Create an account</a>
          <br />
          <br />
          <a href='/forgot_password'>Forgot password?</a>
        </div>
      </CenterForm>
    </>
  );
};

export default LoginForm;
