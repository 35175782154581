/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ls from 'local-storage';
import AppDispatchContext from '../contexts/dispatch-context';

import { logout } from '../actions/menu-actions';

const LogoutForm = () => {
  const navigate = useNavigate();
  const dispatch = useContext(AppDispatchContext);

  const onLogoutClick = () => {
    logout()
      .then((res) => {
        ls('currentUser', null);
        dispatch({ type: 'userLoggedOut' });
        navigate('/');
      });
  };

  return (
    <button
      className='btn-secondary'
      onClick={onLogoutClick}
    >
      Log out
    </button>
  );
};

export default LogoutForm;
