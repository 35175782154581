/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer
      css={(theme) => css`
        height: 50px;
        max-width:${theme.config.columnWidth2};
        margin:auto;
        justify-content: center;
        width:100%;
        padding:0 1rem;
        
        ul {
          padding-left:0;
          display:block;

          & > li {
            font-size:1.3rem;
            list-style:none;
            margin-left:0;
  
            input[type=text] {
              border:1px solid ${theme.colors.border};
              border-radius:2px;
              padding:5px 3px;
            }
            
            & > span {
              display:block;
              font-weight:bold;
              margin-bottom:0.5rem;
            }
  
            ul {
              padding-left:0;
              margin-bottom:2rem;

              li {
                margin-bottom:4px;
              }
            }
          }
        }
      `}
    >
      <ul>
        <li>
          <span>Mailing list</span>
          <ul>
            <li>
              <form
                action='https://tinyletter.com/lunette'
                method='post'
                target='popupwindow'
                onSubmit={() => {
                  window.open('https://tinyletter.com/lunette', 'popupwindow', 'scrollbars=yes,width=800,height=600');
                  return true;
                }}
              >
                <p><input type='text' style={{ width: '140px' }} name='email' id='tlemail' /></p>
                <input type='hidden' value='1' name='embed' />
                <input className='btn-primary' type='submit' value='Subscribe' />
              </form>
            </li>
          </ul>
        </li>
        <li>
          <span>Contact</span>
          <ul>
            <li>
              For support (or just to say hello), email <a href='mailto:support@lunette.app'>support@lunette.app</a>
            </li>
            <li><a href='https://lunette.canny.io/feature-requests' target='_blank' rel='noreferrer'>Request a Feature</a></li>
          </ul>
        </li>
        <li>
          <span>Info</span>
          <ul>
            <li><Link to='/about'>About</Link></li>
            <li><Link to='/terms_of_service'>Terms of Service</Link></li>
            <li><Link to='/privacy_policy'>Privacy Policy</Link></li>
          </ul>
        </li>
      </ul>

    </footer>
  );
};

export default Footer;
