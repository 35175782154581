import fetchWrap from '../lib/fetch-wrap';

export const loadContent = () => fetchWrap.get('/api/users/load_content');

export const getCurrentUser = () => {
  return fetchWrap.get('/api/session/current_user');
};

export const toggleDarkMode = (darkModeEnabled) => {
  return fetchWrap.post(`/api/users/`, {
    body: JSON.stringify({
      darkModeEnabled,
    })
  });
};

export const submitEmailVerification = (emailVerificationCode) => {
  return fetchWrap.post(`/api/session/verify/${emailVerificationCode}`);
};

export const resendVerificationEmail = () => {
  return fetchWrap.post(`/api/users/verify/resend`);
};

export const resetPassword = (email) => {
  return fetchWrap.post(`/api/session/reset_password`, {
    body: JSON.stringify({
      email,
    })
  });
};

export const verifyPasswordToken = (token) => {
  return fetchWrap.post(`/api/session/verify_token`, {
    body: JSON.stringify({
      token,
    })
  });
};

export const setNewPassword = (token, password) => {
  return fetchWrap.post(`/api/session/set_new_password`, {
    body: JSON.stringify({
      token,
      password,
    })
  });
};

export default {
  loadContent,
  getCurrentUser,
};
