import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Navigate, Link } from 'react-router-dom';
import {
  loadSnapshots,
} from '../../actions/editor-actions';
import BackArrow from '../shared/back-arrow';
import CenterColumn from '../shared/center-column';
import ListingTable from '../shared/listing-table';

const Backups = () => {
  const { documentId } = useParams();
  const [snapshots, setSnapshots] = useState([]);

  useEffect(() => {
    loadSnapshots(documentId)
      .then((res) => res.json())
      .then((res) => {
        setSnapshots(res.snapshots);
      });
  }, []);

  if (!Number.isInteger(parseInt(documentId, 10))) {
    return <Navigate to='/' replace />;
  }

  const renderSnapshot = (snapshot) => {
    return (
      <tr>
        <td>
          <Link
            to={`${snapshot.id}`}
          >
            { new Date(snapshot.createdAt).toLocaleString(
              'en-us',
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              },
            )}
          </Link>
        </td>
      </tr>
    );
  };

  const renderSnapshots = () => {
    return snapshots.map(renderSnapshot);
  };

  return (
    <>
      <BackArrow
        url={`/documents/${documentId}`}
        label='Document'
      />
      <CenterColumn>
        <h1>Version history</h1>
        <ListingTable>
          <thead>
            <th>Date</th>
          </thead>

          <tbody>
            {renderSnapshots()}
          </tbody>
        </ListingTable>
      </CenterColumn>
    </>
  );
};

export default Backups;
